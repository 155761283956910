import React from 'react'
import * as _ from 'lodash'
import { withPrefix } from 'gatsby'

import { Container, Row, Col } from 'reactstrap'

class ModuleItem extends React.Component {

  constructor(props){
    super(props)

  }

  render() {

    const details = this.props.details;

    return (

      <Row key={details.frontmatter.url}
       style={{
        width: `90%`,
        backgroundColor: `#fff`,
        boxShadow: `0 7px 18px -2px rgba(0,0,0,.45)`,
        margin: `20px auto`
       }}
      >

        <Col style={{padding: `0`}}>
          <a style={{ boxShadow: 'none' }} href={'/angular' + details.frontmatter.url}>
            <img className="d-none d-md-block" src={withPrefix('/media/angular/' + details.frontmatter.image)} alt={details.frontmatter.title} style={{
              width: `150px`,
              height: `auto`,
              float: `left`,
              margin: `0 20px 0 0`
            }} />
          </a>   
          <div style={{padding: `20px`}}>
            <h2 itemProp="headline" style={{
              fontSize: `1.2em`
            }}>
              <a style={{ boxShadow: 'none', color: `#000` }} href={withPrefix('/angular' + details.frontmatter.url)}>
                {details.frontmatter.title}
              </a>            
            </h2>  

            <p>{details.frontmatter.description}</p>  
          </div> 
        </Col>
      </Row>
    )
  }
}

export default ModuleItem
