import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ModuleItem from '../components/module-item';

class ModulesIndex extends React.Component {

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const title = 'Select a Module'



    return (
      <Layout location={this.props.location} title={title}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
          script={[{
            type: "text/javascript",
            innerHTML: "if('serviceWorker'in navigator){navigator.serviceWorker.register('/sw.js').then(function() {console.log('worker registered')}, function() {})}"
          }]}
        />

        <div>
          {posts.map(({ node }) => {
              return (
                  <ModuleItem key={node.frontmatter.url} details={node} />
              )
          })}
        </div>

      </Layout>
    )
  }
}

export default ModulesIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
        filter: { frontmatter: {type: { eq: "module" }}}, 
        sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            url
            image
            description
          }
        }
      }
    }
  }
`